var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addPersonalMain"},[_c('base-dialog',{staticClass:"addPersonal",attrs:{"visible":_vm.visible,"width":"1100px","showFooter":false,"title":"选择"},on:{"close":_vm.emitVisible}},[_c('div',{staticClass:"content"},[_c('p',[_vm._v(" 若无人员信息，可点击"),_c('span',{staticClass:"link",on:{"click":_vm.addPersonal}},[_vm._v("此处")]),_vm._v("进行新增！ ")]),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.addPersonalFrom,"formAttrs":{
          model: _vm.queryParas,
          labelWidth: '90px',
        },"btnReset":false},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('base-table',{ref:"tableData",attrs:{"columns":_vm.newColumns,"showPage":false,"tableAttrs":{
          data: _vm.tableData,
          stripe: true,
        },"dataSource":_vm.tableData,"isCaculateHeight":false},on:{"select":_vm.select,"selection-change":_vm.change,"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event},"pageIndexChange":_vm.pageIndexChange,"pageSizeChange":_vm.pageSizeChange,"expand-change":_vm.expandChange},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}},{key:"code",fn:function(scope){return [_c('base-table',{ref:'relationshipTableData'+scope.$index,attrs:{"columns":_vm.relationshipTableConfig,"showPage":false,"tableAttrs":{
              data: scope.row.personContactInfos,
              stripe: true,
            },"dataSource":scope.row.personContactInfos,"webPage":false,"isCaculateHeight":false},on:{"select":_vm.selectRelationship,"selection-change":_vm.changeRelationship,"update:dataSource":function($event){return _vm.$set(scope.row, "personContactInfos", $event)},"update:data-source":function($event){return _vm.$set(scope.row, "personContactInfos", $event)}},scopedSlots:_vm._u([{key:"implementSituation",fn:function(newscope){return [_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},on:{"change":function($event){return _vm.checkboxchange(newscope.row,newscope.row.implementSituation, newscope.$index,scope.$index)}},model:{value:(newscope.row.implementSituation),callback:function ($$v) {_vm.$set(newscope.row, "implementSituation", $$v)},expression:"newscope.row.implementSituation"}})]}}],null,true)})]}}])})],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"提 交"},on:{"click":_vm.confirm}}),_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":_vm.emitVisible}})],1)],2),_c('base-dialog',{staticClass:"addPersonal",attrs:{"visible":_vm.addVisible,"width":"1100px","showFooter":false,"title":"新增"},on:{"close":function($event){_vm.addVisible = false}}},[_c('div',[_c('div',{staticStyle:{"text-align":"right","margin-bottom":"10px"}},[_c('base-button',{attrs:{"label":"新增"},on:{"click":function($event){return _vm.addData.push({ enterpriseId: _vm.gysId, personContactInfos: [] })}}})],1),_c('base-table',{attrs:{"columns":_vm.addcolumns,"showPage":false,"tableAttrs":{
          data: _vm.addData,
          stripe: true,
        },"isCaculateHeight":false},on:{"expand-change":_vm.expandChangeAdd},scopedSlots:_vm._u([{key:"address",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"50","placeholder":"请输入"},model:{value:(scope.row.address),callback:function ($$v) {_vm.$set(scope.row, "address", $$v)},expression:"scope.row.address"}})]}},{key:"code",fn:function(scope){return [_c('base-table',{attrs:{"columns":_vm.addRelationTableConfig,"showPage":false,"tableAttrs":{
              data: scope.row.personContactInfos,
              stripe: true,
            },"isCaculateHeight":false},scopedSlots:_vm._u([{key:"personnalName",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"20","placeholder":"请输入"},on:{"input":function($event){scope.row.personnalName = scope.row.personnalName.replace(
                    /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                    ''
                  )}},model:{value:(scope.row.personnalName),callback:function ($$v) {_vm.$set(scope.row, "personnalName", $$v)},expression:"scope.row.personnalName"}})]}},{key:"idCard",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"18","placeholder":"请输入"},on:{"input":function($event){scope.row.idCard = scope.row.idCard.replace(/[^\w]/g, '')},"blur":function($event){return _vm.idCardPhone(scope.row.idCard)}},model:{value:(scope.row.idCard),callback:function ($$v) {_vm.$set(scope.row, "idCard", $$v)},expression:"scope.row.idCard"}})]}},{key:"mobilePhoneNo",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"11","placeholder":"请输入"},on:{"input":function($event){scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                    /[^\d]/g,
                    ''
                  )},"blur":function($event){return _vm.blurPhone(scope.row.mobilePhoneNo)}},model:{value:(scope.row.mobilePhoneNo),callback:function ($$v) {_vm.$set(scope.row, "mobilePhoneNo", $$v)},expression:"scope.row.mobilePhoneNo"}})]}},{key:"address",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"50","placeholder":"请输入"},model:{value:(scope.row.address),callback:function ($$v) {_vm.$set(scope.row, "address", $$v)},expression:"scope.row.address"}})]}},{key:"contactType",fn:function(scope){return [_c('base-select',{attrs:{"placeholder":"请选择","options":_vm.getDictLists('PERSON_CONTACT_TYPE'),"selectedField":['dictId', 'dictName']},model:{value:(scope.row.contactType),callback:function ($$v) {_vm.$set(scope.row, "contactType", $$v)},expression:"scope.row.contactType"}})]}},{key:"action",fn:function(newScope){return [_c('IconButton',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.removeRelation(scope.$index,newScope.$index)}}})]}}],null,true)},[_c('template',{slot:"actionHeader"},[_c('div',{staticClass:"addRelation"},[_c('div',[_vm._v("操作")]),_c('div',{on:{"click":function($event){return _vm.addRelation(scope.row, scope.$index)}}},[_c('i',{staticClass:"el-icon-plus"})])])])],2)]}},{key:"personnerName",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"20","placeholder":"请输入"},on:{"input":function($event){scope.row.personnerName = scope.row.personnerName.replace(
                /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                ''
              )}},model:{value:(scope.row.personnerName),callback:function ($$v) {_vm.$set(scope.row, "personnerName", $$v)},expression:"scope.row.personnerName"}})]}},{key:"idCard",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"18","placeholder":"请输入"},on:{"input":function($event){scope.row.idCard = scope.row.idCard.replace(/[^\w]/g, '')},"blur":function($event){return _vm.idCardPhone(scope.row.idCard)}},model:{value:(scope.row.idCard),callback:function ($$v) {_vm.$set(scope.row, "idCard", $$v)},expression:"scope.row.idCard"}})]}},{key:"mobilePhoneNo",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"11","placeholder":"请输入"},on:{"input":function($event){scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                /[^\d]/g,
                ''
              )},"blur":function($event){return _vm.blurPhone(scope.row.mobilePhoneNo)}},model:{value:(scope.row.mobilePhoneNo),callback:function ($$v) {_vm.$set(scope.row, "mobilePhoneNo", $$v)},expression:"scope.row.mobilePhoneNo"}})]}},{key:"dutiesName",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"20","placeholder":"请输入"},model:{value:(scope.row.dutiesName),callback:function ($$v) {_vm.$set(scope.row, "dutiesName", $$v)},expression:"scope.row.dutiesName"}})]}},{key:"personTypeCode",fn:function(scope){return [_c('base-select',{attrs:{"placeholder":"请选择","multiple":"","options":_vm.getDictLists('PERSONNEL_TYPE'),"selectedField":['dictId', 'dictName']},on:{"change":function($event){return _vm.personTypeName(scope.row.personTypeCode, scope.row)}},model:{value:(scope.row.personTypeCode),callback:function ($$v) {_vm.$set(scope.row, "personTypeCode", $$v)},expression:"scope.row.personTypeCode"}})]}},{key:"action",fn:function(scope){return [_c('IconButton',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.remove(scope.$index)}}})]}}])})],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"提交"},on:{"click":_vm.batchSave}}),_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":function($event){_vm.addVisible = false}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }